div.query-chip {
  height: 25px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-white);

  span {
    padding: 0px 8px;
  }
}
