button.level-button {
  text-transform: none;

  .MuiButton-endIcon {
    color: #a4a4a4;
  }

  &.active {
    .MuiButton-endIcon {
      color: var(--color-medium-blue);
    }
  }

  .chip {
    border-radius: 24px;
    gap: 10px;
    padding: 4px 0px;
    span {
      color: var(--color-carbone);
      font-weight: 800;
    }
  }
}
