.manage-groups {
  background: rgba(241, 243, 247, 0.5);
  border-width: 0px 0px 1px 1px;
  border-style: solid;
  border-color: var(--color-grey-100);
  height: 68px;
  align-items: center;
  display: flex;

  .text {
    padding-left: 22px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;

    color: var(--color-carbone);
  }

  .modify-groups {
    padding: 6px 8px;
    width: 35%;
    height: 68px;

    background-color: var(--color-medium-blue);

    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 130%;
    text-transform: none;
    border-radius: 0;
    color: var(--color-white);

    .MuiButton-startIcon {
      margin-right: 14px;
    }
  }

  .manage-buttons-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    height: 100%;
    div {
      display: flex;
      justify-content: flex-start;
      height: 100% !important;
      width: 35%;
      align-items: center;
      .search-input {
        height: auto;
        border-width: 0px 0px 0px 1px;
        background-color: var(--color-white);
      }
    }
  }
}

.list-groups {
  width: 100%;
  height: calc(100vh - 206px);
  overflow: auto;
  padding-left: 28px;
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: var(--color-grey-100);

  .checkbox {
    min-width: 20px;

    .Mui-checked {
      color: var(--color-medium-blue);
    }
    svg {
      font-size: 24px;
    }
  }

  .expand-icon {
    margin-left: 8px;
    color: var(--color-carbone);
    svg {
      font-size: 24px;
    }
  }

  .list-title-text {
    span {
      font-family: Avenir;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: var(--color-carbone);
    }
    &.disabled {
      span {
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }

  .item {
    padding-top: 2px;
    padding-bottom: 2px;

    .list-text {
      width: fit-content;
      display: flex;
      flex-grow: 0;
      span {
        width: fit-content;
        font-family: Avenir;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: var(--color-carbone);
      }

      &.disabled {
        span {
          color: rgba(0, 0, 0, 0.26);
        }
      }
    }

    .role-cell {
      display: flex;
      margin-left: 10px;
    }
  }

  .loading {
    height: calc(100% - (var(--userbar-height) * 2 + var(--topbar-height)));
  }
}
