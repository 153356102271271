.permissions-modal {
  .main {
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 90%;

    .header {
      padding-bottom: 0;
    }

    .sync-error {
      display: flex;
      width: 100%;

      .text {
        color: var(--color-red-600);
        width: 100%;
        padding-left: 10px;
      }
    }

    .slug {
      margin-top: 12px;
      width: 100%;
      margin-bottom: 12px;
      color: var(--color-grey-500);
    }
  }

  .table-container {
    width: 100%;

    #info-panel {
      .container {
        width: 35% !important;
      }
    }

    .MuiTableContainer-root {
      overflow-y: auto;
      overflow-x: auto;
      height: 100%;
      border-width: 1px 1px 1px 1px;
      border-style: solid;
      border-color: var(--color-grey-100);
      .MuiTableHead-root {
        height: 56px;
        background: var(--color-white);

        .MuiTableCell-root {
          border-width: 0px 1px 1px 0px;
          border-style: solid;
          border-color: var(--color-grey-100);
          padding: 21px 16px;
          font-family: Avenir;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: var(--color-carbone);
        }

        .MuiTableCell-root:last-of-type {
          border-right: none;
        }
      }

      .MuiTableBody-root {
        border: 1px solid red !important;
        .MuiTableRow-root {
          height: 56px;
          border-left: 1px solid red;
          .MuiTableCell-root {
            height: 56px;
            padding-top: 0;
            padding-bottom: 0;
            font-weight: 500;
            font-size: 14px;
            color: var(--color-carbone);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border: none;
          }
        }
      }
    }
  }
}
