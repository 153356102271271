.token-actions {
  display: flex;
  column-gap: 16px;

  span {
    width: fit-content;
  }
  .button {
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: fit-content;
    padding: 5px;
    height: 35px;
    border: 1px solid var(--color-medium-blue);
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: var(--color-medium-blue);
    text-transform: none !important;

    &.warning {
      color: var(--color-red-600);
      border-color: var(--color-red-600);

      &:hover {
        border-color: var(--color-red-600);
      }
    }
  }
}
