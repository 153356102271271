.group-main-container {
  height: calc(100vh - ((var(--topbar-height))));
  width: 100%;
  display: flex;
  .loading {
    height: calc(100vh - var(--topbar-height) * 2);
  }

  .group-tree {
    height: 100%;
    flex: 1;
    overflow: hidden;
  }

  .group-details {
    width: 100%;
    flex: 2;
    height: 100%;
  }
}
