:root {
  // colors
  --color-carbone: #242830;
  --color-carbone-300: #797979;
  --color-carbone-500: #545454;

  --color-white: #ffffff;
  --color-near-white: #f5f6f6;
  --color-lighter-grey: #eeeeee;
  --color-light-grey: #c6c6c6;
  --color-medium-grey: #838383;
  --color-near-black: #272727;
  --color-grey-900: #3c3c3b;
  --color-grey-800: #575f6f;
  --color-grey-500: #919191;

  --color-grey-300: #c8c8c8;
  --color-grey-200: #e3e3e3;
  --color-grey-100: #e2e7ef;

  --color-blue-500: #41a8f9;
  --color-blue-600: #1294f8;
  --color-blue-700: #0673c6;
  --color-blue-900: #022e4f;
  --color-light-blue: #f1f8ff;
  --color-medium-blue: #546efe;

  --color-yellow-500: #fecc00;
  --color-light-yellow: #fdf2d0;

  --color-red-400: #fd7b62;
  --color-red-500: #fc5130;
  --color-red-600: #e83f3f;

  --color-green-500: #04e762;
  --color-green-900: #09c959;

  --color-warning-900: #ee9207;

  --color-purple-500: #832667;

  --color-personal-token: #f7eaf9;
  --color-external-token: #def7de;
  --color-service-token: #f6f5c5;

  --color-valid-token: #31c752;
  --color-pending-token: #ee9207;
  --color-rejected-token: #eb604d;

  --color-level1: #e3dafd;
  --color-level2: #fdf6da;

  --color-success: #31c752;
  --color-pending: #ee9207;
  --color-failure: #ef6161;
  // sizes
  --topbar-height: 72px;
  --userbar-height: 68px;
  --tabsbar-height: 48px;
}
