.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .backdrop-loader {
    z-index: 1000;
    background-color: #ffffffe8;
    color: #9aa6e4;
  }

  .main {
    overflow: auto;
    display: flex;
    justify-content: center;
    border: 1px solid #256eff;
    width: 100%;
    flex-wrap: wrap;

    width: 40%;
    max-height: 90%;

    padding: 24px 32px 32px 32px;
    border-radius: 4px;

    .header {
      display: flex;
      align-items: start;
      justify-content: space-between;
      padding-bottom: 32px;
      width: 100%;

      .title {
        font-family: Avenir;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: var(--color-medium-blue);

        .subtitle {
          font-size: 14px;
          color: #242830;
        }
      }

      .close-button {
        color: var(--color-medium-blue);
      }
    }

    .body {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }

    .footer {
      .button {
        height: 56px;
        flex: 0.6;
        background-color: var(--color-medium-blue);
        border-radius: 30px;
        font-family: Avenir;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        color: #f8f8f8;

        &.Mui-disabled {
          opacity: 0.3;
        }
      }
    }
  }
}
