$pagination-size: 8%;

.details-main {
  width: 100%;
  height: 100%;
  border-left: 1px solid var(--color-grey-100);

  .table-container {
    height: calc((100% - (67px)));
    width: auto;
  }

  .MuiTableContainer-root {
    overflow: auto;
    height: calc(100% - $pagination-size);
    border-bottom: 1px solid var(--color-grey-100);

    .MuiTableHead-root {
      height: 56px;
      background: var(--color-white);
      border-style: solid;
      border-color: var(--color-grey-100);

      .MuiTableCell-root {
        border-width: 1px 1px;
        border-style: solid;
        border-color: var(--color-grey-100);
        border-left: none;
        padding: 21px 16px;
        font-family: Avenir;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: var(--color-carbone);
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root:has(.requests) {
        height: 72px;
      }

      .requests {
        background-color: #e3dafd;
        text-align: center;

        > span {
          font-weight: 800;
        }

        .show-btn {
          margin-left: 24px;
          background-color: #ffffff;
          border: 1px solid var(--color-medium-blue);
          color: var(--color-medium-blue);
          padding: 8px 10px;
        }
      }

      .MuiTableRow-root {
        height: 56px;
        .MuiTableCell-root {
          height: 56px;
          padding-top: 0;
          padding-bottom: 0;
          font-family: Avenir;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: var(--color-carbone);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          border: none;
        }
      }
    }
  }

  .pagination {
    height: ($pagination-size);

    button {
      outline: none;
    }
    :not(.Mui-selected) {
      border: none;
    }
    .Mui-selected {
      border: none;
      color: #304ffe;
      background-color: #f8f9fb;
    }

    .MuiPaginationItem-previousNext {
      background-color: #f8f9fb;
      color: #b0bac9;
      svg {
        font-size: 26px;
      }
    }
  }
}
