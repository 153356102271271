.user-creation-modal {
  height: auto !important;
  max-height: 90% !important;
  .creation-feedback {
    margin-bottom: 32px !important;
    .creation-text {
      margin-top: 30px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 140%;
      letter-spacing: 0.01em;
      color: #242830;
    }
  }

  .creation-list {
    max-height: 20vh;
    overflow: auto; 
    margin-bottom: 48px;
    .username-chip {
      margin-right: 12px;
      margin-bottom: 12px;
      background: #F1F4FA;
      height: 36px;
      font-family: Avenir;
      font-style: normal;
      font-weight:  400;
      font-size: 16px;
      color: var(--color-carbone);
    }
  }
}