.MuiAutocomplete-paper {
  background: var(--color-white);
  border: 1px solid var(--color-grey-100);
  border-radius: 0;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08), -2px -2px 7px rgba(0, 0, 0, 0.06);
}

.MuiAutocomplete-option {
  height: 49px;
  .option-name {
    font-family: Avenir;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 120%;
    color: var(--color-near-black);
  }

  .option-email {
    word-break: break-all;
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #a7a7a7;
    margin-left: 16px;
  }

  .option-add-icon {
    display: flex;
    align-items: center;

    button span {
      font-family: Avenir;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      padding-left: 6px;
      color: var(--color-medium-blue);
    }

    svg {
      font-size: 18px;
      color: var(--color-medium-blue);
    }
  }

  .option-import-list {
    height: 56px;
    button {
      padding-left: 0;
    }
    span {
      padding-left: 16px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: var(--color-medium-blue);
    }
  }

  &[aria-selected="true"]:not(:hover) {
    background-color: revert !important ;
  }

  &[aria-selected="true"]:hover {
    background-color: #0000000a !important ;
  }
}
