.MuiSwitch-root {
  border-radius: 12px !important;
  width: 36px !important;
  height: 20px !important;
  padding: 0 !important;
}
.MuiSwitch-switchBase {
  padding: 2px 0 0 0 !important;

  .MuiSwitch-thumb {
    width: 16px;
    height: 16px;
    color: var(--color-white) !important;
    margin-left: 2px;
  }

  &.Mui-checked {
    + .MuiSwitch-track {
      background-color: #546efe !important;
      opacity: 1 !important;
    }

    .MuiSwitch-thumb {
      width: 16px;
      height: 16px;
      color: var(--color-white) !important;
      margin-left: -2px;
    }
  }
  + .MuiSwitch-track {
    background-color: #546efe !important;
    opacity: 0.3 !important;
  }
}
