.mode-wrapper {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;

  > span {
    font-size: 12px;
    color: #8a8a8a;
  }

  .MuiFormGroup-root {
    margin-top: 18px;
  }
}
