.request-modal {
  .main {
    width: 60%;
    height: fit-content;
    max-height: 80%;
    .body {
      .table-container {
        width: 100%;
        height: fit-content;
        margin-bottom: 36px;

        .MuiTableContainer-root {
          overflow-y: auto;
          overflow-x: auto;
          max-height: 100%;
          border-width: 1px 1px 1px 1px;
          border-style: solid;
          border-color: var(--color-grey-100);
          .MuiTableHead-root {
            height: 56px;
            background: var(--color-white);

            .MuiTableCell-root {
              border-width: 0px 1px 1px 0px;
              border-style: solid;
              border-color: var(--color-grey-100);
              padding: 21px 16px;
              font-weight: 600;
              font-size: 14px;
              line-height: 100%;
              color: var(--color-carbone);
            }

            .MuiTableCell-root:last-of-type {
              border-right: none;
            }
          }

          .MuiTableBody-root {
            .MuiTableRow-root {
              height: 56px;

              &:last-of-type {
                .MuiTableCell-root {
                  border-bottom: none;
                }
              }

              .MuiTableCell-root {
                height: 56px;
                border-width: 0px 1px 1px 0px;
                border-style: solid;
                border-color: var(--color-grey-100);
                font-weight: 500;
                font-size: 14px;
                padding-left: 16px;
                padding-right: 16px;
                color: var(--color-carbone);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                .MuiTableCell-root:last-of-type {
                  border: none;
                }

                .information {
                  color: var(--color-failure);
                  font-size: 14px;

                  &.success {
                    color: var(--color-success);
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer .btn {
      flex: 0.4;
    }
  }
}
