.input-container {
  margin-bottom: 34px;
  width: 100%;
  .field {
    &:not(:has(textarea)) {
      height: 56px;
    }
    width: 100%;
    padding: 16px;
    border: 1px solid #a4bbdf;
    border-radius: 4px;
  }

  .label {
    padding-bottom: 14px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    align-items: center;
    color: #8a8a8a;
  }
}
