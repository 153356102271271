.confirmation-wrapper {
  flex: 1;

  .message {
    color: var(--color-carbone);

    .title {
      margin-bottom: 26px;
      font-size: 16px;
      font-weight: 800;
    }

    .name {
      font-size: 14px;
      display: flex;
      flex-direction: column;

      &:last-child {
        margin-bottom: 26px;
      }
    }
  }
}
