.authorization-popup {
  .main {
    width: 35%;
    height: fit-content;
    max-height: 80%;

    .list {
      width: 100%;
      gap: 10px;
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
    }

    .switch-mode {
      margin-top: 10px;
      width: 100%;

      .MuiFormControlLabel-root {
        margin-left: 0px;

        .MuiSwitch-root {
          margin-right: 8px;
        }

        .MuiTypography-root {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .button {
      margin-top: 48px;
      height: 56px;
      flex: 0.6;
      background-color: var(--color-medium-blue);
      border-radius: 30px;
      font-family: Avenir;
      box-shadow: none;
      font-style: normal;
      font-weight: 700;
      text-transform: none;
      font-size: 16px;
      line-height: 130%;
      color: #f8f8f8;

      &.Mui-disabled {
        opacity: 0.3;
      }
    }
  }
}
