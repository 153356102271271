.details-bar {
  height: 67px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .group-menu {
    height: inherit;
    flex: 0.6 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fb;
    border-right: 1px solid var(--color-grey-100);
  }
  .details-group {
    display: flex;
    align-items: center;
    .name {
      padding-left: 22px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: var(--color-carbone);
    }
    .count {
      padding-left: 10px;
      font-family: Avenir;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: var(--color-carbone);
    }
  }

  .usergroup-close {
    padding: 22px 24px;
    button {
      outline: none;
    }
  }

  .buttons-container {
    display: flex;
    flex: 1;
    height: 100%;
    .search-input {
      flex: 1;
      border-left: 0;
    }
    .custom-button {
      background-color: var(--color-medium-blue);
      text-transform: none;
      border: none;
      border-radius: 0;
      box-shadow: none;

      font-family: Avenir;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      padding: 24px;

      &.icon {
        border-left: 1px solid white;
        color: var(--color-white);
        flex: 0.1;
        padding: 22px 24px 22px 24px;
        span {
          padding: 0px;
          margin-right: 0px;
        }
      }

      span {
        padding-right: 8px;
      }
      .MuiButton-startIcon {
        svg {
          font-size: 22px;
        }
      }

      .MuiBadge-badge {
        background-color: var(--color-red-500);
        border: 0.8px solid white;
        left: 10px;
      }
    }
  }
}
