$accept-color: #31c752;
$reject-color: #fe5454;

.action-buttons {
  display: flex;
  gap: 12px;

  button {
    text-transform: none;
    padding: 6px;

    &.approve {
      color: $accept-color;
      border-color: $accept-color;

      &.active {
        background-color: $accept-color;
        color: white;
      }
    }
    &.reject {
      color: $reject-color;
      border-color: $reject-color;

      &.active {
        background-color: $reject-color;
        color: white;
      }
    }
  }
}
