button.mail-button {
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  color: #546efe;
  text-transform: none;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }

  .MuiLoadingButton-loadingIndicator {
    right: -10px;
  }
}
