.roles {
  margin-left: 6px;

  .chip {
    width: fit-content;
    margin-right: 10px;
    height: 25px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 14px;
    background-color: #e0f9e1;

    &.level1 {
      background-color: #fdf6da;
    }

    &.level2 {
      background-color: #e3dafd;
    }
  }
}
