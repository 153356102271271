.membership-modal {
  .main {
    width: 50%;
    height: fit-content;
    max-height: 80%;

    &:has(.confirmation-wrapper) {
      width: 40%;
    }
  }
  .body {
    .table-container {
      width: 100%;
      height: fit-content;

      #info-panel {
        .container {
          width: 35% !important;
        }
      }

      .MuiTableContainer-root {
        overflow-y: auto;
        overflow-x: auto;
        max-height: 100%;
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: var(--color-grey-100);
        .MuiTableHead-root {
          height: 56px;
          background: var(--color-white);

          .MuiTableCell-root {
            border-width: 0px 1px 1px 0px;
            border-style: solid;
            border-color: var(--color-grey-100);
            padding: 21px 16px;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: var(--color-carbone);
          }

          .MuiTableCell-root:last-of-type {
            border-right: none;
          }
        }

        .MuiTableBody-root {
          .MuiTableRow-root {
            height: 56px;

            &:last-of-type {
              .MuiTableCell-root {
                border-bottom: none;
              }
            }

            .MuiTableCell-root {
              height: 56px;
              border-width: 0px 1px 1px 0px;
              border-style: solid;
              border-color: var(--color-grey-100);
              font-weight: 500;
              font-size: 14px;
              padding-left: 16px;
              padding-right: 16px;
              color: var(--color-carbone);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              .MuiTableCell-root:last-of-type {
                border: none;
              }

              .MuiInputBase-root {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .custom-footer {
    display: flex;
    justify-content: center;

    &:has(.cancel-btn) {
      width: 70%;
      gap: 18px;
    }

    .confirm-btn,
    .cancel-btn {
      flex: 1;
      height: 56px;
      box-shadow: none;
      background-color: var(--color-medium-blue);
      border-radius: 30px;
      font-weight: 500;
      font-size: 16px;
      line-height: 115%;
      color: #f8f8f8;
      text-transform: none;

      &.cancel-btn {
        background-color: #ffffff;
        color: var(--color-carbone);
        border: 1px solid #b7bac1;
      }

      &.Mui-disabled {
        opacity: 0.3;
      }
    }
  }
}
