$user-count: #4583ff;

.group-container {
  width: 100%;
  height: calc(100% - 68px);
  overflow-y: auto;

  .service-title {
    width: fit-content;
    flex-direction: row-reverse;
  }

  .group-title-text {
    .service {
      font-family: Avenir;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 100%;
      color: var(--color-carbone);
    }

    .count {
      font-weight: 400;
      font-size: 14px;
      color: $user-count;
    }
  }

  .group-text {
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-carbone);
  }

  .group-list-item {
    height: 26px;
    padding-bottom: 6px;

    .requests {
      font-weight: 800;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .group-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .user-count {
    font-weight: 400;
    color: $user-count;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .expand-icon {
    margin-left: 8px;
    color: var(--color-carbone);
    svg {
      font-size: 24px;
    }
  }
}
