div.user-chip {
  background-color: var(--color-medium-blue);
  color: white;
  font-size: 16px;
  font-weight: 800;

  svg path {
    color: white;
  }
}
