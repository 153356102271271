.level-select {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 18px;

  .user {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .name {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
