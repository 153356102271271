.ask-token {
  &.modal-wrapper {
    .main {
      height: auto;
      max-height: 80%;
    }
  }

  .main {
    width: 40%;

    .container {
      margin-bottom: 34px;
      .field {
        height: 56px;
        width: 100%;
        padding: 16px;
        border: 1px solid #d3ddec;
        border-radius: 4px;

        &.multiline {
          height: auto;
          min-height: 56px;
        }
      }

      .label {
        padding-bottom: 14px;
        font-family: Avenir;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        align-items: center;
        color: #8a8a8a;
      }
    }

    .search-input {
      background-color: var(--color-white);
      border: 1px solid #d1dced;
      border-radius: 4px;
      padding: 16px 16px 16px 12px;

      &.Mui-focused {
        border: 1px solid var(--color-medium-blue);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08),
          -2px -2px 7px rgba(0, 0, 0, 0.06);
      }

      margin-bottom: 40px;
    }

    .expiry {
      path {
        fill: #546efe;
      }
    }

    .expiry {
      padding-left: 10px;
      margin-bottom: 20px;
      .date-limit {
        padding-left: 16px;
        color: var(--color-medium-blue);
      }
    }

    .confirmation {
      width: 100%;
      display: flex;
      flex-direction: column;

      .title {
        white-space: pre-line;
        font-weight: 800;
      }

      .reason {
        white-space: pre-line;
      }

      .permissions {
        display: flex;
        overflow: auto;
        max-height: 80%;
        flex-direction: column;
      }
    }

    .modify-info {
      width: 100%;

      .subtitle {
        font-size: 14px;
        margin-top: -40px;
        margin-bottom: 40px;
      }

      .text {
        padding-left: 8px;
        color: #242830;
        font-weight: 800;
      }
      margin-bottom: 40px;
    }

    .button {
      margin-top: 48px;
      height: 56px;
      flex: 0.6;
      background-color: var(--color-medium-blue);
      border-radius: 30px;
      font-family: Avenir;
      box-shadow: none;
      font-weight: 700;
      text-transform: none;
      font-size: 16px;
      color: #f8f8f8;

      &:disabled {
        opacity: 0.3;
      }

      &.cancel {
        background-color: #ffffff;
        color: var(--color-carbone);
        border: 1px solid #b7bac1;

        &:focus {
          background-color: unset;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: center;
      flex: 1;
      gap: 16px;
      .button {
        flex: 0.35;
      }
    }

    .permission-count {
      margin-top: 12px;
      width: 100%;
      margin-bottom: 12px;
      color: var(--color-grey-500);
    }
  }

  .table-container {
    max-height: 50vh;
    width: 100vw;

    #info-panel {
      .container {
        width: 35% !important;
      }
    }

    .MuiTableContainer-root {
      overflow-y: auto;
      overflow-x: auto;
      height: 100%;
      border-width: 1px 1px 1px 1px;
      border-style: solid;
      border-color: var(--color-grey-100);
      .MuiTableHead-root {
        height: 56px;
        background: var(--color-white);

        .MuiTableCell-root {
          border-width: 0px 1px 1px 0px;
          border-style: solid;
          border-color: var(--color-grey-100);
          padding: 21px 16px;
          font-family: Avenir;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 100%;
          color: var(--color-carbone);
        }

        .MuiTableCell-root:last-of-type {
          border-right: none;
        }
      }

      .MuiTableBody-root {
        border: 1px solid red !important;
        .MuiTableRow-root {
          height: 56px;
          border-left: 1px solid red;
          .MuiTableCell-root {
            height: 56px;
            padding-top: 0;
            padding-bottom: 0;
            font-family: Avenir;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: var(--color-carbone);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border: none;
          }
        }
      }
    }
  }
}
