.autocomplete {
  flex: 1;
  .textfield-autocomplete {
    height: 68px;
  }
  .MuiInput-root {
    flex: 1;
    border: 1px solid #d3ddec;
    border-radius: 4px;
    padding-left: 25px;
    padding-right: 22px;
    padding-bottom: 0;

    input.MuiInputBase-input {
      padding-left: 20px;
    }
  }
}
