.modal-wrapper {
  .group-title {
    font-weight: 600;
  }

  .inputs {
    width: 100%;
    max-height: 70%;
    overflow: auto;
  }

  .custom-footer {
    margin-top: 36px;

    .MuiBadge-badge {
      background-color: var(--color-red-500);
    }
  }

  .underline-button {
    font-size: 14px;
    font-weight: 800;
    line-height: 14px;
    color: #505b66;
    text-transform: none;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }

    svg {
      margin-right: 8px;
    }

    &.permission {
      color: var(--color-medium-blue);
    }
  }

  .level-select {
    margin-bottom: 18px;
    max-height: 40vh;
    overflow: auto;
  }

  .add-admin {
    margin-top: 18px;
    margin-bottom: 40px;
  }
}
