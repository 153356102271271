.modify-user-group {
  .main {
    height: initial;
    max-height: 90%;

    .body {
      flex-direction: column;

      .checkboxes {
        display: flex;
        flex-direction: column;
        margin-bottom: 56px;

        .Mui-checked {
          color: var(--color-medium-blue);
        }
      }

      .chip-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 12px;
        row-gap: 8px;
        margin-bottom: 32px;
        overflow: auto;
      }

      .label {
        font-family: Avenir;
        font-size: 12px;
        line-height: 12px;
        text-align: left;
        color: #8a8a8a;
        margin-bottom: 24px;
      }
    }

    .button {
      height: 56px;
      flex: 0.6;
      background-color: var(--color-medium-blue);
      border-radius: 30px;
      font-weight: 800;
      font-size: 16px;
      line-height: 130%;
      color: #f8f8f8;

      &.Mui-disabled {
        opacity: 0.3;
      }
    }
  }
}
