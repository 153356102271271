.usernames-label {
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  padding-bottom: 12px;
}

div.usernames-input {
  background-color: var(--color-white);
  border: 1px solid #d1dced;
  border-radius: 4px;
  padding: 16px 16px 16px 12px;

  &.Mui-focused {
    border: 1px solid var(--color-medium-blue);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08),
      -2px -2px 7px rgba(0, 0, 0, 0.06);
  }
}
